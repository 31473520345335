.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.circular_image {
    border-radius: 50%;
    width: 250px;
    height: 250px;
    margin: 30px 30px 30px 30px;
}

.company_logo {
    width: 150px; /* you can use % */
    height: auto;
    margin: 20px 50px 20px 50px;

}

.App {
    background-color: #e0e0ff;
    font-family: 'Maison Neue', sans-serif;
    font-weight: 300;
    font-size: large;
}

.body {
    background-color: #e0e0ff;
}

.navbar {
    background-color: #a0a0ff;
    font-family: 'Maison Neue', sans-serif;
    font-weight: bold;
}

/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
    color: white;
}

/* change the link color */
.navbar-custom .navbar-nav .nav-link {
    color: white;
}

body > #root > div {
    height: 100vh;
  }